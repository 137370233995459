// src/pages/ExistingBlogs.jsx
import React, { useEffect, useState } from 'react';
import { db } from '../firebase/Config';
import { collection, query, orderBy, limit, startAfter, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import NProgress from 'nprogress';

const ExistingBlogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [blogToDelete, setBlogToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [stats, setStats] = useState({ total: 0 });
  const navigate = useNavigate();

  const fetchBlogs = async () => {
    NProgress.start();
    setLoading(true);
    try {
      const q = query(collection(db, "blogs"), orderBy("publishedDate", "desc"), limit(10));
      const snapshot = await getDocs(q);
      const newBlogs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setBlogs(newBlogs); // Replace instead of append
      setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
      
      setStats({
        total: snapshot.size // Update to show actual count
      });
    } catch (error) {
      console.error("Error fetching blogs:", error);
    } finally {
      setLoading(false);
      NProgress.done();
    }
  }

  const loadMore = async () => {
    if (!lastDoc) return;
    
    NProgress.start();
    setLoading(true);
    try {
      const q = query(
        collection(db, "blogs"), 
        orderBy("publishedDate", "desc"), 
        startAfter(lastDoc), 
        limit(10)
      );
      const snapshot = await getDocs(q);
      const newBlogs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setBlogs(prev => [...prev, ...newBlogs]); // Append only for load more
      setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
      
      setStats(prev => ({
        ...prev,
        total: prev.total + snapshot.size
      }));
    } catch (error) {
      console.error("Error loading more blogs:", error);
    } finally {
      setLoading(false);
      NProgress.done();
    }
  }

  useEffect(() => {
    fetchBlogs();
    // eslint-disable-next-line
  }, []);

  const handleEdit = (id) => {
    NProgress.start();
    navigate(`/edit-blog/${id}`);
    setTimeout(() => NProgress.done(), 500);
  }

  const handleAddNew = () => {
    NProgress.start();
    navigate('/add-blog');
    setTimeout(() => NProgress.done(), 500);
  }

  const handleDelete = async (id) => {
    setShowConfirm(true);
    setBlogToDelete(id);
  };

  const confirmDelete = async () => {
    if (blogToDelete) {
      await deleteDoc(doc(db, "blogs", blogToDelete));
      setBlogs(blogs.filter(blog => blog.id !== blogToDelete));
      setShowConfirm(false);
      setBlogToDelete(null);
    }
  };

  const cancelDelete = () => {
    setShowConfirm(false);
    setBlogToDelete(null);
  };

  const filterControls = () => (
    <div className="mb-6 flex flex-wrap gap-4 items-center">
      <div className="flex-1">
        <input
          type="search"
          placeholder="Search blogs..."
          className="w-full p-2 border rounded"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="flex gap-4 text-sm">
        <span className="px-2 py-1 bg-gray-100 rounded">Total: {stats.total}</span>
      </div>
    </div>
  );

  return (
    <div className="ml-64 p-8 h-screen overflow-y-auto custom-scrollbar">
      <div className="max-w-6xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-3xl font-bold">Existing Blogs</h2>
          <button 
            onClick={handleAddNew}
            className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors transform active:scale-95 duration-200"
          >
            New Blog
          </button>
        </div>

        {/* Enhanced filter controls */}
        <div className="bg-white p-6 rounded-lg shadow-sm mb-8">
          {filterControls()}
        </div>

        {/* Blog cards with improved layout */}
        <div className="grid gap-6">
          {blogs.map(blog => (
            <div key={blog.id} className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow">
              <div className="flex gap-6">
                {blog.thumbnailUrl && (
                  <img 
                    src={blog.thumbnailUrl} 
                    alt={blog.title} 
                    className="w-48 h-32 object-cover rounded-lg flex-shrink-0" 
                  />
                )}
                <div className="flex-grow">
                  <h3 className="text-xl font-bold mb-2">{blog.title}</h3>
                  <p className="text-gray-600 mb-4">{blog.shortDescription}</p>
                  <div className="flex items-center justify-between">
                    <div className="text-sm text-gray-500">
                      <span>Published: {new Date(blog.publishedDate.seconds * 1000).toLocaleDateString()}</span>
                      {blog.editedDate && <span> | Edited: {new Date(blog.editedDate.seconds * 1000).toLocaleDateString()}</span>}
                      <span> | By: {blog.publishedBy}</span>
                    </div>
                    <div className="flex gap-2">
                      <button onClick={() => handleEdit(blog.id)} 
                        className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors transform active:scale-95 duration-200">
                        Edit
                      </button>
                      <button onClick={() => handleDelete(blog.id)} 
                        className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors transform active:scale-95 duration-200">
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        
        {lastDoc && blogs.length >= 10 && (
          <button 
            onClick={loadMore} 
            disabled={loading} 
            className="mt-6 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 disabled:bg-gray-400 transform active:scale-95 transition-transform duration-200"
          >
            {loading ? 'Loading...' : 'Load More'}
          </button>
        )}
        {showConfirm && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded shadow">
              <p>Are you sure you want to delete this blog?</p>
              <div className="mt-4 flex justify-end">
                <button onClick={confirmDelete} className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600">Yes</button>
                <button onClick={cancelDelete} className="ml-2 px-4 py-2 bg-gray-300 rounded hover:bg-gray-400">No</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ExistingBlogs;
