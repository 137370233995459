// src/pages/AddBlog.jsx
import React, { useState } from 'react';
import { db } from '../firebase/Config';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import ReactMarkdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import rehypeHighlight from 'rehype-highlight';
import 'highlight.js/styles/github.css';
import NProgress from 'nprogress';

const MAX_URL_LENGTH = 30;

const AddBlog = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    publishedBy: '',
    title: '',
    shortDescription: '',
    content: '',
    thumbnailUrl: '',
    url: ''
  });
  const [tags, setTags] = useState([]);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleUrlChange = (e) => {
    const value = e.target.value
      .replace(/\s+/g, '-')
      .toLowerCase()
      .replace(/[^a-z0-9-]/g, '')
      .slice(0, MAX_URL_LENGTH);
    
    setForm(prev => ({
      ...prev,
      url: value
    }));
  };

  const handleTagInput = (e) => {
    if (e.key === 'Enter' && e.target.value) {
      setTags([...tags, e.target.value.trim()]);
      e.target.value = '';
    }
  };

  const removeTag = (indexToRemove) => {
    setTags(tags.filter((_, index) => index !== indexToRemove));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!form.publishedBy.trim()) newErrors.publishedBy = 'Author name is required';
    if (!form.title.trim()) newErrors.title = 'Title is required';
    if (!form.shortDescription.trim()) newErrors.shortDescription = 'Description is required';
    if (!form.content.trim()) newErrors.content = 'Content is required';
    if (!form.thumbnailUrl.trim()) newErrors.thumbnailUrl = 'Thumbnail URL is required';
    if (tags.length === 0) newErrors.tags = 'At least one tag is required';
    if (!form.url.trim()) newErrors.url = 'URL is required';
    if (!/^[a-z0-9-]+$/.test(form.url)) {
      newErrors.url = 'URL can only contain lowercase letters, numbers, and hyphens';
    }
    if (form.url.length > MAX_URL_LENGTH) {
      newErrors.url = `URL must be ${MAX_URL_LENGTH} characters or less`;
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      toast.error("Please fill in all required fields");
      return;
    }

    NProgress.start();
    try {
      const docRef = doc(db, "blogs", form.url);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        setErrors(prev => ({ ...prev, url: 'This URL is already taken' }));
        toast.error("URL already exists. Please choose a different one.");
        return;
      }

      await setDoc(docRef, {
        ...form,
        publishedDate: serverTimestamp(),
        editedDate: null,
        tags
      });

      toast.success("Blog added successfully!");
      navigate('/'); // Remove setTimeout and navigate immediately
    } catch (error) {
      console.error("Error adding blog:", error);
      toast.error("Failed to add blog");
    } finally {
      NProgress.done();
    }
  }

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Form section - add custom-scrollbar class */}
      <div className="w-[50%] ml-64 p-8 overflow-y-auto custom-scrollbar">
        <form onSubmit={handleSubmit} className="max-w-none space-y-6">
          <h2 className="text-3xl font-bold mb-8">Add New Blog</h2>
          
          {/* Form fields with improved spacing */}
          <div className="space-y-6">
            <div>
              <label className="text-sm font-semibold mb-2 block">
                Published By <span className="text-red-500">*</span>
              </label>
              <input type="text" name="publishedBy" value={form.publishedBy} onChange={handleChange} 
                className={`w-full p-3 border rounded-lg outline-none transition-all duration-200
                ${errors.publishedBy ? 'border-red-500' : 'focus:form-input-focus'}`} required />
              {errors.publishedBy && (
                <p className="text-red-500 text-sm mt-1">{errors.publishedBy}</p>
              )}
            </div>

            <div>
              <label className="text-sm font-semibold mb-2 block">
                Title <span className="text-red-500">*</span>
              </label>
              <input type="text" name="title" value={form.title} onChange={handleChange} 
                className={`w-full p-3 border rounded-lg outline-none transition-all duration-200
                ${errors.title ? 'border-red-500' : 'focus:form-input-focus'}`} required />
              {errors.title && (
                <p className="text-red-500 text-sm mt-1">{errors.title}</p>
              )}
            </div>

            <div>
              <label className="text-sm font-semibold mb-2 block">
                Short Description <span className="text-red-500">*</span>
              </label>
              <textarea name="shortDescription" value={form.shortDescription} onChange={handleChange} 
                className={`w-full p-3 border rounded-lg outline-none transition-all duration-200
                ${errors.shortDescription ? 'border-red-500' : 'focus:form-input-focus'}`} required />
              {errors.shortDescription && (
                <p className="text-red-500 text-sm mt-1">{errors.shortDescription}</p>
              )}
            </div>

            <div>
              <label className="text-sm font-semibold mb-2 block">
                Tags <span className="text-red-500">*</span>
              </label>
              <div className="flex flex-wrap gap-2 mb-2">
                {tags.map((tag, index) => (
                  <span key={index} className="bg-blue-100 px-2 py-1 rounded flex items-center">
                    {tag}
                    <button 
                      type="button"
                      onClick={() => removeTag(index)}
                      className="ml-2 text-red-500"
                    >
                      &times;
                    </button>
                  </span>
                ))}
              </div>
              <input
                type="text"
                placeholder="Type and press Enter to add tags"
                onKeyPress={handleTagInput}
                className={`w-full p-3 border rounded-lg outline-none transition-all duration-200
                ${errors.tags ? 'border-red-500' : 'focus:form-input-focus'}`}
              />
              {errors.tags && (
                <p className="text-red-500 text-sm mt-1">{errors.tags}</p>
              )}
            </div>

            <div>
              <label className="text-sm font-semibold mb-2 block">
                Thumbnail URL <span className="text-red-500">*</span>
              </label>
              <input 
                type="url" 
                name="thumbnailUrl" 
                value={form.thumbnailUrl} 
                onChange={handleChange} 
                placeholder="Enter image URL for blog thumbnail"
                className={`w-full p-3 border rounded-lg outline-none transition-all duration-200
                ${errors.thumbnailUrl ? 'border-red-500' : 'focus:form-input-focus'}`} 
              />
              {errors.thumbnailUrl && (
                <p className="text-red-500 text-sm mt-1">{errors.thumbnailUrl}</p>
              )}
              {form.thumbnailUrl && (
                <img src={form.thumbnailUrl} alt="Thumbnail preview" className="h-32 w-auto object-cover rounded mt-2" />
              )}
            </div>

            <div>
              <label className="text-sm font-semibold mb-2 block">
                Blog URL <span className="text-red-500">*</span>
              </label>
              <input 
                type="text" 
                name="url" 
                value={form.url} 
                onChange={handleUrlChange}
                placeholder="my-blog-post"
                className={`w-full p-3 border rounded-lg outline-none transition-all duration-200
                ${errors.url ? 'border-red-500' : 'focus:form-input-focus'}`}
              />
              {errors.url && (
                <p className="text-red-500 text-sm mt-1">{errors.url}</p>
              )}
              <p className="text-sm text-gray-500 mt-1">
                This will be the URL of your blog post. Use only lowercase letters, numbers, and hyphens.
              </p>
            </div>

            <div>
              <label className="text-sm font-semibold mb-2 block">
                Content (Markdown) <span className="text-red-500">*</span>
              </label>
              <textarea name="content" value={form.content} onChange={handleChange} 
                className={`w-full p-3 border rounded-lg outline-none transition-all duration-200
                ${errors.content ? 'border-red-500' : 'focus:form-input-focus'} h-48`} required />
              {errors.content && (
                <p className="text-red-500 text-sm mt-1">{errors.content}</p>
              )}
            </div>

            <div className="bg-white py-4 border-t mt-8">
              <button 
                type="submit" 
                className="w-full py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors transform active:scale-95 duration-200"
              >
                Publish Blog
              </button>
            </div>
          </div>
        </form>
      </div>
      
      {/* Preview section - add custom-scrollbar class */}
      <div className="w-[50%] h-screen overflow-y-auto border-l custom-scrollbar">
        <div className="h-full bg-gray-50 p-8">
          <div className="sticky top-0 bg-gray-50 py-4 mb-4">
            <h3 className="text-2xl font-bold">Preview</h3>
          </div>
          <div className="prose prose-lg max-w-3xl bg-white p-8 rounded-lg shadow-sm">
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw, rehypeSanitize, rehypeHighlight]}
              components={{
                img: ({node, ...props}) => (
                  <img className="max-w-full h-auto" alt={props.alt || 'Blog content image'} {...props} />
                ),
                table: ({node, ...props}) => (
                  <table className="border-collapse table-auto w-full" {...props} />
                ),
                th: ({node, ...props}) => (
                  <th className="border border-gray-300 px-4 py-2 bg-gray-100" {...props} />
                ),
                td: ({node, ...props}) => (
                  <td className="border border-gray-300 px-4 py-2" {...props} />
                )
              }}
            >
              {form.content}
            </ReactMarkdown>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddBlog;