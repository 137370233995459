// src/firebase/config.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyBf1NRcfzZQWoLHRAfaz0ZGC5dXp8XCfS4",
    authDomain: "youthxconnect-blogs.firebaseapp.com",
    projectId: "youthxconnect-blogs",
    storageBucket: "youthxconnect-blogs.firebasestorage.app",
    messagingSenderId: "280096780260",
    appId: "1:280096780260:web:69ce2d885b96100b2ea1f1",
    measurementId: "G-QRB0YF94EQ"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };
