// src/App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import Layout from './Layout';
import ExistingBlogs from './pages/ExistingBlogs';
import AddBlog from './pages/AddBlog';
import EditBlog from './pages/EditBlog';
import ProtectedRoute from './ProtectedRoute';

function App() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={
        <ProtectedRoute>
          <Layout>
            <ExistingBlogs />
          </Layout>
        </ProtectedRoute>
      } />
      <Route path="/add-blog" element={
        <ProtectedRoute>
          <Layout>
            <AddBlog />
          </Layout>
        </ProtectedRoute>
      } />
      <Route path="/edit-blog/:id" element={
        <ProtectedRoute>
          <Layout>
            <EditBlog />
          </Layout>
        </ProtectedRoute>
      } />
      {/* Add more routes as needed */}
    </Routes>
  );
}

export default App;
