// src/components/Sidebar.jsx
// Remove NProgress imports and related code
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PencilAltIcon, LogoutIcon, DocumentTextIcon } from '@heroicons/react/outline';
import { auth } from '../firebase/Config';
import { signOut } from 'firebase/auth';
// import NProgress from 'nprogress'; // Remove this line

const Sidebar = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const handleLogout = async () => {
        const confirmed = window.confirm("Are you sure you want to logout?");
        if (confirmed) {
            try {
                await signOut(auth);
                // Redirect handled by auth state
            } catch (error) {
                console.error("Logout Error:", error);
            }
        }
    }

    const handleNavigation = (path) => {
        navigate(path);
        // Remove NProgress handling
    };

    const menuItems = [
        { name: 'Existing Blogs', path: '/', icon: DocumentTextIcon },
        { name: 'Add New Blog', path: '/add-blog', icon: PencilAltIcon },
    ];

    return (
        <div className="fixed left-0 top-0 h-screen w-64 bg-gray-800 text-white flex flex-col justify-between z-10">
            <nav className="mt-10">
                {menuItems.map((item) => (
                    <Link 
                        to={item.path} 
                        key={item.name} 
                        onClick={() => handleNavigation(item.path)}
                        className={`flex items-center mt-4 py-2 px-6 ${location.pathname === item.path ? 'bg-gray-700' : ''}`}
                    >
                        <item.icon className="h-6 w-6" />
                        <span className="mx-3">{item.name}</span>
                    </Link>
                ))}
            </nav>
            <button onClick={handleLogout} className="flex items-center mt-4 mb-10 py-2 px-6 hover:bg-gray-700">
                <LogoutIcon className="h-6 w-6" />
                <span className="mx-3">Logout</span>
            </button>
        </div>
    );
}

export default Sidebar;