// src/pages/Login.jsx
import React, { useState } from 'react';
import { auth } from '../firebase/Config';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import Popup from '../components/Popup';
import ReCAPTCHA from 'react-google-recaptcha';

const Login = () => {
  const [form, setForm] = useState({ email: '', password: '' });
  const [showReset, setShowReset] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [popupMsg, setPopupMsg] = useState('');
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setForm(prev => ({ ...prev, [e.target.name]: e.target.value }));
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!captchaVerified) {
      setPopupMsg("Please complete the CAPTCHA");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 3000);
      return;
    }
    try {
      await signInWithEmailAndPassword(auth, form.email, form.password);
      navigate('/');
    } catch (error) {
      setPopupMsg(error.message);
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 3000);
    }
  }

  const handlePasswordReset = async () => {
    if (!captchaVerified) {
      setPopupMsg("Please complete the CAPTCHA");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 3000);
      return;
    }
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      setPopupMsg("Password reset email sent!");
      setShowPopup(true);
      setShowReset(false);
      setResetEmail('');
      setTimeout(() => setShowPopup(false), 3000);
    } catch (error) {
      setPopupMsg(error.message);
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 3000);
    }
  }

  const onCaptchaChange = (value) => {
    setCaptchaVerified(!!value);
  }

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="bg-white p-8 rounded shadow-md w-full max-w-md">
        <h2 className="text-2xl font-semibold mb-6 text-center">Admin Login</h2>
        {!showReset ? (
          <form onSubmit={handleLogin}>
            <label className="block mb-2">Email</label>
            <input type="email" name="email" value={form.email} onChange={handleChange} className="w-full p-2 border rounded mb-4" required />

            <label className="block mb-2">Password</label>
            <input type="password" name="password" value={form.password} onChange={handleChange} className="w-full p-2 border rounded mb-4" required />

            <ReCAPTCHA
              sitekey="6Leys3EqAAAAAEjkHcbcAjaoJ1Bf4o1xcG8W1Hv9"
              onChange={onCaptchaChange}
            />

            <button type="submit" className="w-full px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600" disabled={!captchaVerified}>Login</button>
          </form>
        ) : (
          <div>
            <label className="block mb-2">Enter your email for password reset:</label>
            <input type="email" value={resetEmail} onChange={(e) => setResetEmail(e.target.value)} className="w-full p-2 border rounded mb-4" required />

            <ReCAPTCHA
              sitekey="6Leys3EqAAAAAEjkHcbcAjaoJ1Bf4o1xcG8W1Hv9"
              onChange={onCaptchaChange}
            />

            <button onClick={handlePasswordReset} className="w-full px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600" disabled={!captchaVerified}>Send Reset Email</button>
          </div>
        )}
        {!showReset && (
          <div className="mt-4 text-center">
            <button onClick={() => setShowReset(true)} className="text-blue-500 hover:underline">Forgot Password?</button>
          </div>
        )}
      </div>
      {showPopup && <Popup message={popupMsg} />}
    </div>
  );
}

export default Login;