// src/pages/EditBlog.jsx
import React, { useEffect, useState } from 'react';
import { db } from '../firebase/Config';
import { doc, getDoc, updateDoc, setDoc, deleteDoc, serverTimestamp } from 'firebase/firestore';
import { useParams, useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { toast } from 'react-toastify';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import rehypeHighlight from 'rehype-highlight';
import 'highlight.js/styles/github.css';
import NProgress from 'nprogress';

const MAX_URL_LENGTH = 30;

const EditBlog = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [blog, setBlog] = useState(null);
  const [form, setForm] = useState({
    publishedBy: '',
    title: '',
    shortDescription: '',
    content: '',
    image: '',
    thumbnailUrl: '',
    url: '' // Add this line
  });
  const [tags, setTags] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchBlog = async () => {
      NProgress.start();
      const docRef = doc(db, "blogs", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setBlog(data);
        setForm({
          publishedBy: data.publishedBy || '',
          title: data.title || '',
          shortDescription: data.shortDescription || '',
          content: data.content || '',
          image: data.image || '',
          thumbnailUrl: data.thumbnailUrl || '',
          url: id  // Initialize URL with current document ID
        });
        setTags(data.tags || []);
      } else {
        toast.error("Blog not found!");
        navigate('/');
      }
      NProgress.done();
    }
    fetchBlog();
  }, [id, navigate]);

  const handleChange = (e) => {
    setForm(prev => ({ ...prev, [e.target.name]: e.target.value }));
  }

  const handleUrlChange = (e) => {
    const value = e.target.value
      .replace(/\s+/g, '-')
      .toLowerCase()
      .replace(/[^a-z0-9-]/g, '')
      .slice(0, MAX_URL_LENGTH);
    
    setForm(prev => ({
      ...prev,
      url: value
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!form.publishedBy.trim()) newErrors.publishedBy = 'Author name is required';
    if (!form.title.trim()) newErrors.title = 'Title is required';
    if (!form.shortDescription.trim()) newErrors.shortDescription = 'Description is required';
    if (!form.content.trim()) newErrors.content = 'Content is required';
    if (!form.thumbnailUrl.trim()) newErrors.thumbnailUrl = 'Thumbnail URL is required';
    if (!form.url.trim()) newErrors.url = 'URL is required';
    if (!/^[a-z0-9-]+$/.test(form.url)) {
      newErrors.url = 'URL can only contain lowercase letters, numbers, and hyphens';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      toast.error("Please fill in all required fields");
      return;
    }

    NProgress.start();
    try {
      const currentDocRef = doc(db, "blogs", id);
      const updateData = {
        ...form,
        editedDate: serverTimestamp(),
        tags
      };

      if (form.url !== id) {
        // URL has changed, create new doc and delete old one
        const newDocRef = doc(db, "blogs", form.url);
        const docSnap = await getDoc(newDocRef);
        
        if (docSnap.exists()) {
          setErrors(prev => ({ ...prev, url: 'This URL is already taken' }));
          toast.error("URL already exists. Please choose a different one.");
          return;
        }

        // Create new document with new URL
        await setDoc(newDocRef, updateData);
        // Delete old document
        await deleteDoc(currentDocRef);
      } else {
        // Update existing document
        await updateDoc(currentDocRef, updateData);
      }

      toast.success("Blog updated successfully!");
      navigate('/');
    } catch (error) {
      console.error("Error updating blog:", error);
      toast.error("Failed to update blog: " + error.message);
    } finally {
      NProgress.done();
    }
  }

  const handleTagInput = (e) => {
    if (e.key === 'Enter' && e.target.value) {
      setTags([...tags, e.target.value.trim()]);
      e.target.value = '';
    }
  };

  const removeTag = (indexToRemove) => {
    setTags(tags.filter((_, index) => index !== indexToRemove));
  };

  if (!blog) return <div>Loading...</div>;

  return (
    <div className="flex h-screen overflow-hidden">
      <div className="w-[50%] ml-64 p-8 overflow-y-auto custom-scrollbar">
        <form onSubmit={handleSubmit} className="max-w-none">
          <h2 className="text-3xl font-bold mb-8">Edit Blog</h2>
          
          <label className="block mb-2">Published By</label>
          <input type="text" name="publishedBy" value={form.publishedBy} onChange={handleChange} className="w-full p-2 border rounded mb-4" required />
          {errors.publishedBy && <p className="text-red-500">{errors.publishedBy}</p>}

          <label className="block mb-2">Title</label>
          <input type="text" name="title" value={form.title} onChange={handleChange} className="w-full p-2 border rounded mb-4" required />
          {errors.title && <p className="text-red-500">{errors.title}</p>}

          <label className="block mb-2">Short Description</label>
          <textarea name="shortDescription" value={form.shortDescription} onChange={handleChange} className="w-full p-2 border rounded mb-4" required />
          {errors.shortDescription && <p className="text-red-500">{errors.shortDescription}</p>}

          {/* <label className="block mb-2">Image URL</label>
          <input type="text" name="image" value={form.image} onChange={handleChange} className="w-full p-2 border rounded mb-4" /> */}

          {/* Add URL field */}
          <div>
            <label className="block mb-2">Blog URL</label>
            <input 
              type="text" 
              name="url" 
              value={form.url} 
              onChange={handleUrlChange}
              className="w-full p-2 border rounded mb-4"
            />
            {errors.url && <p className="text-red-500">{errors.url}</p>}
          </div>

          {/* Add Tags field */}
          <div>
            <label className="block mb-2">Tags</label>
            <div className="flex flex-wrap gap-2 mb-2">
              {tags.map((tag, index) => (
                <span key={index} className="bg-blue-100 px-2 py-1 rounded flex items-center">
                  {tag}
                  <button 
                    type="button"
                    onClick={() => removeTag(index)}
                    className="ml-2 text-red-500"
                  >
                    ×
                  </button>
                </span>
              ))}
            </div>
            <input
              type="text"
              placeholder="Type and press Enter to add tags"
              onKeyPress={handleTagInput}
              className="w-full p-2 border rounded mb-4"
            />
          </div>

          <label className="block mb-2">Content (Markdown)</label>
          <textarea name="content" value={form.content} onChange={handleChange} className="w-full p-2 border rounded mb-4 h-48" required />
          {errors.content && <p className="text-red-500">{errors.content}</p>}

          <div className="mb-4">
            <label className="block mb-2">Thumbnail URL</label>
            <input 
              type="url" 
              name="thumbnailUrl" 
              value={form.thumbnailUrl} 
              onChange={handleChange} 
              placeholder="Enter image URL for blog thumbnail"
              className="w-full p-2 border rounded mb-4" 
            />
            {form.thumbnailUrl && (
              <img src={form.thumbnailUrl} alt="Thumbnail preview" className="h-32 w-auto object-cover rounded" />
            )}
          </div>

          <div className="bg-white py-4 border-t mt-8">
            <button 
              type="submit" 
              className="w-full py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors transform active:scale-95 duration-200"
            >
              Update Blog
            </button>
          </div>
        </form>
      </div>
      
      <div className="w-[50%] h-screen overflow-y-auto border-l custom-scrollbar">
        <div className="h-full bg-gray-50 p-8">
          <h3 className="text-xl font-semibold mb-2">Preview</h3>
          <div className="prose prose-blue max-w-none bg-white p-4 rounded shadow">
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw, rehypeSanitize, rehypeHighlight]}
              components={{
                img: ({node, ...props}) => (
                  <img className="max-w-full h-auto" alt={props.alt || 'Blog content image'} {...props} />
                ),
                table: ({node, ...props}) => (
                  <table className="border-collapse table-auto w-full" {...props} />
                ),
                th: ({node, ...props}) => (
                  <th className="border border-gray-300 px-4 py-2 bg-gray-100" {...props} />
                ),
                td: ({node, ...props}) => (
                  <td className="border border-gray-300 px-4 py-2" {...props} />
                )
              }}
            >
              {form.content}
            </ReactMarkdown>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditBlog;
