// src/components/Popup.jsx
import React from 'react';

const Popup = ({ message, type = 'success', onClose }) => {
  const bgColor = type === 'error' ? 'bg-red-50' : 'bg-green-50';
  const textColor = type === 'error' ? 'text-red-800' : 'text-green-800';
  const iconColor = type === 'error' ? 'text-red-400' : 'text-green-400';

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 modal-enter">
      <div className={`${bgColor} p-4 rounded-lg shadow-lg max-w-sm w-full mx-4`}>
        <div className="flex items-center">
          <div className={`${iconColor} mr-3`}>
            {type === 'error' ? 
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              :
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
            }
          </div>
          <div className={`${textColor} font-medium`}>{message}</div>
        </div>
        {/* Removed the "Yes" and "No" buttons to fix linter errors */}
      </div>
    </div>
  );
};

// If Popup is no longer used for notifications, you can safely remove this component.
// Ensure that it's not used elsewhere in your project before deletion.

export default Popup;
